import React from "react"
import Helmet from "react-helmet"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import image_1 from "../images/image_1.jpg";

const CooperationRules = ({ location }) => (
  <Layout>
    <Helmet>
      <script
        src="https://kit.fontawesome.com/0842dd585c.js"
        crossorigin="anonymous"
      ></script>
    </Helmet>
    <SEO title="Zasady współpracy" />
    <div className="container">
      <div className="main">
        <ol>
          <li><Link to="/">Strona główna</Link> / <Link to="/cooperation-rules">Zasady współpracy</Link></li>
        </ol>
        <article>
          <h1>Zasady współpracy:</h1>
          <hr></hr>
          <div className="row">
            <div className="col-md-6">
              <h1>Oferta dla Klienta:</h1>
              <ul>
                <li>Przyjazny i łatwy w obsłudze interface</li>
                <li>Szeroka gama porduktów personalizowanych ECO oraz PREMIUM</li>
                <li>Specjalne rabaty na cały asortyment towarów dla:</li>
                <ul>
                  <li>Fotografów</li>
                  <li>Agencji Reklamowych</li>
                  <li>Sklepów Internetowych</li>
                  <li>Producentów gadżetów</li>
                  <li>Projektantów wnętrz</li>
                </ul>
                <li>Rabat 50% na ekspozycję</li>
                <li>Wsparcie logistyczne</li>
                <li>Fachowe doradztwo i opieka nad Klientem</li>
                <li>Systematyczna kontrola zamówień</li>
                <li>Maksymalny komfort zakupów przez internet</li>
              </ul>

              <p>Rabat 50% na ekspozycję od cen regularnych</p>
              <p>
                Zapraszmy do zapoznania się z ofertą naszych usług, z możliwościami udostępnionego
                przez nas narzędzia oraz do składania zamówień.<br />
                Jesteśmy otwarci na Państwa uwagi i sugestie
              </p>
              <p>W przypadku zapytań o większe ilości jednego produktu z tą samą grafiką, możliwa jest negocjacja cen. Zapraszamy do rozmów!</p>
              <p>
                Zachęcamy również do zamawiania wybranych, pojedzyńczych produktów
                z Państwa grafiką lub zdjęciem na ekspozycję, w celu lepszej prezentacji dla Klienta. Ma to szczególne znaczenie w przypadku  artykułów tekstylnych
                oraz ekskluzywnych paneli aluminiowych ChromaLuxe.
              </p>
            </div>
            <div className="col-md-6">
              <img src={image_1} alt="Fototransfer.pl" className="image-responsive" />
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-4">
              <a href="https://www.fototransfer.pl/regulamin-sklepu">
                <div className="box__download">
                  <h4><i className="fas fa-link"></i> Regulamin sklepu</h4>
                </div>
              </a>
            </div>
            <div className="col-md-4">
              <a href="https://www.fototransfer.pl/polityka-prywatnosci">
                <div className="box__download">
                  <h4><i className="fas fa-link"></i> Polityka prywatności</h4>
                </div>
              </a>
            </div>
          </div>
        </article>
        </div>
      </div>
    {/* <Link to="/">Go back to the homepage</Link> */}
  </Layout>
)

export default CooperationRules
